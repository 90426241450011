import React from 'react';
import { Button, Paper, Box, Typography, Card, Skeleton, CardContent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

const SkeletonCard = () => (
    <Card sx={{ marginTop: '15px' }}>
        <CardContent>
            <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                {[...Array(3)].map((_, index) => (
                    <Skeleton
                        key={index}
                    />
                ))}
            </Typography>
        </CardContent>
    </Card>
);


const ProductList = ({ allProducts, handleAddToCart, disabledButtons }) => {
    return (
        <Box sx={{
            height: '520px',
        }}>
            {allProducts.length === 0 ? (
                <>
                    {[...Array(4)].map((_, index) => (
                        <SkeletonCard key={index} />
                    ))}
                </>
            ) : (
                <Box sx={{
                    height: '100%',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        display: 'none'
                    },
                }}>
                    {allProducts.map((product, index) => (
                        <Button
                            key={index}
                            onClick={() => handleAddToCart(product, index)}
                            disableRipple
                            sx={{
                                width: '100%',
                                textAlign: 'left',
                                borderRadius: 0,
                                margin: 0,
                                padding: '10px',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                },
                            }}
                        >
                            <Paper
                                elevation={3}
                                sx={{
                                    display: 'flex',
                                    p: 2,
                                    mb: 2,
                                    height: '100%',
                                    width: '100%',
                                    border: disabledButtons.includes(index) ? '2px solid #6f00ff' : 'none',
                                    '&:hover': {
                                        backgroundColor: '#F1F1F1',
                                    }
                                }}
                            >
                                <Box sx={{ textTransform: 'capitalize' }}>
                                    <Typography variant="body1" >
                                        {product.product_title}
                                    </Typography>
                                    <Typography variant="body2">{product.product_duration}</Typography>
                                    <Typography variant="body2">{product.product_price} €</Typography>
                                </Box>
                                <Box sx={{ marginLeft: 'auto' }}>
                                    {disabledButtons.includes(index) ?
                                        <CheckIcon sx={{ color: 'whitesmoke', backgroundColor: 'blue', borderRadius: '5px', opacity: .6 }} />
                                        :
                                        <AddIcon sx={{ color: '#454545', backgroundColor: '#E7E7E7', borderRadius: '5px' }} />}
                                </Box>
                            </Paper>
                        </Button>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default ProductList;
