import { Box } from '@mui/material'
import React from 'react'
import './errorAnimation.scss'
export default function ErrorAnimation() {
    return (
        <Box>
            <div className="container">
                <div className="circle-border"></div>
                <div className="circle">
                    <div className="error"></div>
                </div>
            </div>
        </Box>
    )
}
