import { Box } from '@mui/material'
import React from 'react'
import './successAnimation.scss'

export default function SuccessAnimation() {
    return (
        <Box>
            <div className="success-checkmark">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
        </Box>
    )
}
