import React, { useState, useEffect } from 'react';
import Navbar from '../navbar/Navbar';
// import Cookies from '../cookies/Cookies';
import { Typography, Paper, Box, Grid, Container, Button, Avatar, TextField } from '@mui/material';
import Footer from '../footer/Footer';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ProductList from '../product-list/ProductList';
import ChooseABarber from '../choose-a-barber/ChooseABarber';
import Booking from '../booking/Booking';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BookingForm from '../booking-form/BookingForm';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { parse, format } from 'date-fns';
import ErrorAnimation from './error-animation/ErrorAnimation';
import SuccessAnimation from './success-animation/SuccessAnimation';

export default function BookNow() {

    const baseURL = 'https://barbers-back-end.onrender.com';
    const getProductsURL = 'https://barbers-back-end.onrender.com/products';
    const imageURL = "https://barbers-back-end.onrender.com/images/";

    // const baseURL = "http://localhost:8080";
    // const imageURL = 'http://localhost:8080/images/';
    // const getProductsURL = "http://localhost:8080/products";

    const [allProducts, setAllProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedBarbers, setSelectedBarbers] = useState([]);
    const [disabledButtons, setDisabledButtons] = useState([]);
    const [breadcrumbStep, setBreadcrumbStep] = useState(0);
    const [selectedDate, setSelectedDate] = useState([]);
    const [selectedDateTime, setSelectedDateTime] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [discountCodeValue, setDiscountCodeValue] = useState('');
    const [discountCodeName, setDiscountCodeName] = useState('');
    const [discountError, setDiscountError] = useState('');
    const [ws, setWs] = useState(null);


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    });

    const handleCloseBooking = () => {
        setSuccessDialogOpen(false);
        window.location.reload();
    };


    const handleDiscountCodeChange = (e) => {
        setDiscountCodeValue(e.target.value);
    };

    const handleActivateDiscount = async () => {
        try {
            const response = await axios.get(`${baseURL}/discount-codes`, {
                withCredentials: true
            });
            const discountCodes = response.data;

            const matchedDiscount = discountCodes.find(
                (code) => code.newsletter_discount_code_value === discountCodeValue
            );

            if (matchedDiscount) {
                setDiscountCodeName(matchedDiscount.newsletter_discount_code_name);
                setDiscountError('');
            } else {
                setDiscountCodeName('');
                setDiscountError('Invalid discount code. Please try again.');
            }
        } catch (error) {
            console.error('Error fetching discount codes:', error);
            setDiscountError('Failed to fetch discount codes. Please try again later.');
        }
    };
    //WebSocket Effect
    useEffect(() => {
        const socket = new WebSocket('wss://barbers-back-end.onrender.com');

        socket.onopen = () => {
            setWs(socket);
        };

        socket.onclose = () => {
            setWs(null);
        };

        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, []);
    //WebSocket Ends

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(getProductsURL, {
                    withCredentials: true
                });
                setAllProducts(response.data.products);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const handleAddDateToCart = (hour, shiftIndex, date) => {
        const isInCart = selectedDate.length > 0;
        const formattedDate = format(date, 'EEEE dd MMMM');
        const newSelectedDateTime = `${formattedDate} at ${hour} `;

        if (isInCart) {
            setSelectedDateTime((prevSelectedDateTime) => newSelectedDateTime);
            setSelectedDate([date]);
        } else {
            setSelectedDateTime((prevSelectedDateTime) => newSelectedDateTime);
            setSelectedDate([date]);
        }
    };


    const handleAddBarberToCart = (barber, index) => {
        const isInCart = selectedBarbers.length > 0;

        if (isInCart) {
            setSelectedBarbers([barber]);
        } else {
            setSelectedBarbers([barber]);
        }
    };



    const handleAddToCart = (product, index) => {
        const isInCart = selectedProducts.some((item) => item.product_id === product.product_id);

        if (isInCart) {
            const updatedProducts = selectedProducts.filter((item) => item.product_id !== product.product_id);
            setSelectedProducts(updatedProducts);

            const updatedDisabledButtons = disabledButtons.filter((btnIndex) => btnIndex !== index);
            setDisabledButtons(updatedDisabledButtons);
        } else {
            setSelectedProducts([...selectedProducts, product]);
            setDisabledButtons([...disabledButtons, index]);
        }
    };

    const calculateTotalPrice = () => {
        if (selectedProducts.length === 0) {
            return null;
        }

        let totalPriceWithoutDiscount = selectedProducts.reduce((total, product) => total + product.product_price, 0);

        if (discountCodeName) {
            const discountAmount = (totalPriceWithoutDiscount * discountCodeName) / 100;
            totalPriceWithoutDiscount -= discountAmount;
        }
        return totalPriceWithoutDiscount;
    }

    const calculateTotalTime = () => {
        if (selectedProducts.length === 0) {
            return null;
        }
        return selectedProducts.reduce((totalTime, product) => totalTime + parseInt(product.product_duration), 0);
    };

    const handleFormSubmit = (formData) => {
        console.log('Form Data from BookingForm:', formData);
    };

    const validateName = (name) => /^[A-Za-zÀ-ÖØ-öø-ÿΑ-Ωα-ωĀ-ž]{3,}$/.test(name);
    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validatePhone = (phone) => phone.replace(/\D/g, '').length >= 8;

    const handleProceed = () => {



        if (breadcrumbStep === 0 && selectedProducts.length === 0) {
            setDialogOpen(true);
            setDialogContent('Please select at least one product.');
        } else if (breadcrumbStep === 0 && selectedProducts.length > 0) {
            setBreadcrumbStep(1);
            // console.log('Products Data:', {
            //     selectedProducts: selectedProducts,
            //     totalPrice: calculateTotalPrice(),
            //     totalDuration: calculateTotalTime(),
            // });
        }
        else if (breadcrumbStep === 1 && selectedBarbers.length === 0) {
            setDialogOpen(true);
            setDialogContent('Please select a barber.');
        } else if (breadcrumbStep === 1 && selectedBarbers.length > 0) {
            setBreadcrumbStep(2);
            // console.log('Barber Data:', {
            //     selectedBarbers: selectedBarbers,
            // });
        }
        else if (breadcrumbStep === 2 && selectedDateTime.length === 0) {
            setDialogOpen(true);
            setDialogContent('Please select a date and time.');
        } else if (breadcrumbStep === 2 && selectedDateTime.length > 0) {
            setBreadcrumbStep(3);
            // console.log('Date Time:', {
            //     selectedDateTime: selectedDateTime,
            // });
        } else if (breadcrumbStep === 3) {
            const newErrors = {};

            if (!validateName(formData.firstName)) {
                newErrors.firstName = 'First name must contain only letters and be at least 3 characters long.';
            }

            if (!validateName(formData.lastName)) {
                newErrors.lastName = 'Last name must contain only letters and be at least 3 characters long.';
            }

            if (!validateEmail(formData.email)) {
                newErrors.email = 'Invalid email format (example: email@gmail.com.)';
            }

            if (!validatePhone(formData.phone)) {
                newErrors.phone = 'Phone number must have at least 8 digits.';
            }

            if (Object.keys(newErrors).length > 0) {
                setDialogContent(Object.values(newErrors).join('\n'));
                setDialogOpen(true);
            } else {
                const currentYear = new Date().getFullYear();
                const parsedDateTime = parse(selectedDateTime, "EEEE dd MMMM 'at' HH:mm", new Date());
                const formattedDateTime = format(parsedDateTime, 'yyyy-MM-dd HH:mm:ss');
                // Adjust the format to eliminate extra spaces
                const formattedDateTimeWithoutSpaces = formattedDateTime.replace(/\s+/g, ' ');

                const requestData = {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    phone: formData.phone,
                    products: selectedProducts.map(product => product.product_title).join(', '),
                    barber: selectedBarbers.map(barber => barber.user_id).join(', '),
                    appointmentTime: formattedDateTimeWithoutSpaces,
                    totalPrice: calculateTotalPrice(),
                    totalDuration: calculateTotalTime(),
                };
                /*Web Socket*/
                const modifiedData = {
                    type: 'reservation',
                    data: {
                        firstName: requestData.firstName,
                        lastName: requestData.lastName,
                        email: requestData.email,
                        phone: requestData.phone,
                        products: requestData.products,
                        barber: requestData.barber,
                        appointmentTime: requestData.appointmentTime,
                        totalPrice: requestData.totalPrice,
                        totalDuration: requestData.totalDuration
                    }
                };

                if (ws && ws.readyState === WebSocket.OPEN) {
                    const message = JSON.stringify(modifiedData);
                    ws.send(message);
                } else {
                    console.error('WebSocket connection is not open');
                }
                /* !!! Web Socket!!! */
                axios.post(
                    `${baseURL}/reservation`,
                    requestData,
                    {
                        withCredentials: true,

                    }
                )
                    .then((response) => {
                        const successMessage = 'Thanks for choosing us! We eagerly anticipate your visit.';
                        const title = 'Success';
                        setDialogMessage(successMessage);
                        setDialogTitle(title);
                        setSuccessDialogOpen(true);
                    })
                    .catch((error) => {
                        const errorMessage = 'Reservation failed';
                        const title = 'Error';
                        setDialogMessage(errorMessage);
                        setDialogTitle(title);
                        setSuccessDialogOpen(true);
                    });
            }
        } else {
            console.warn('No data selected to proceed.');
        }
    };



    const handleBreadcrumbClick = (step) => {
        if (step <= breadcrumbStep) {
            setBreadcrumbStep(step);
        } else {
            console.warn('Cannot navigate to a higher breadcrumb step.');
        }
    };

    const renderStepComponent = () => {
        switch (breadcrumbStep) {
            case 0:
                return <ProductList
                    allProducts={allProducts}
                    handleAddToCart={handleAddToCart}
                    disabledButtons={disabledButtons}
                />;
            case 1:
                return <ChooseABarber
                    selectedBarbers={selectedBarbers}
                    onAddToCart={handleAddBarberToCart}
                    selectedProducts={selectedProducts}
                />;
            case 2:
                return <Booking
                    selectedBarbers={selectedBarbers}
                    selectedProducts={selectedProducts}
                    onAddDateToCart={handleAddDateToCart}
                    totalPrice={calculateTotalPrice()}
                    totalDuration={calculateTotalTime()}
                />;
            case 3:
                return <BookingForm
                    formData={formData}
                    setFormData={setFormData}
                    selectedBarbers={selectedBarbers}
                    selectedProducts={selectedProducts}
                    onAddDateToCart={handleAddDateToCart}
                    totalPrice={calculateTotalPrice()}
                    totalDuration={calculateTotalTime()}
                    onFormSubmit={handleFormSubmit}
                />;
            default:
                return null;
        }
    };

    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Navbar />
            <Container maxWidth="xl" sx={{ minHeight: '100vh' }}>
                <Grid container spacing={2} alignItems="stretch">
                    <Grid item xs={12} sx={{ marginTop: '10px' }}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                onClick={() => handleBreadcrumbClick(0)}
                                disabled={breadcrumbStep < 0}
                            >
                                <Typography variant="body1" color="textSecondary">Services</Typography>
                            </Button>
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                onClick={() => handleBreadcrumbClick(1)}
                                disabled={breadcrumbStep < 1}
                            >
                                <Typography variant="body1" color="textSecondary">Choose a Barber</Typography>
                            </Button>
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                onClick={() => handleBreadcrumbClick(2)}
                                disabled={breadcrumbStep < 2}
                            >
                                <Typography variant="body1" color="textSecondary">Book Now</Typography>
                            </Button>
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                onClick={() => handleBreadcrumbClick(3)}
                                disabled={breadcrumbStep < 3}
                            >
                                <Typography variant="body1" color="textSecondary">Appointment Form</Typography>
                            </Button>
                        </Breadcrumbs>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        {renderStepComponent()}
                    </Grid>

                    {/* Right Side - Selected Products */}
                    <Grid item xs={12} md={4} >
                        <Paper
                            elevation={3}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 2,
                                mb: 2,
                                height: 'auto',
                            }}
                        >
                            <Typography variant="h6">
                                Overview
                            </Typography>
                            <Box>
                                {selectedProducts.map((selectedProduct, index) => (
                                    <Paper
                                        key={index}
                                        elevation={3}
                                        sx={{
                                            display: 'flex',
                                            p: 2,
                                            mb: 2,
                                            mt: 2,
                                            height: 'auto',
                                        }}
                                    >
                                        <Box>
                                            <Typography variant="body1">{selectedProduct.product_title}</Typography>
                                            <Typography variant="body2">{selectedProduct.product_duration}</Typography>
                                            <Typography variant="body2">{selectedProduct.product_price} €</Typography>
                                        </Box>
                                    </Paper>
                                ))}
                            </Box>


                            <Box>
                                {selectedBarbers.map((selectedBarber, index) => (
                                    <Paper
                                        key={index}
                                        elevation={3}
                                        sx={{
                                            display: 'flex',
                                            p: 2,
                                            mb: 2,
                                            mt: 2,
                                            height: 'auto',
                                        }}
                                    >

                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', gap: '10px' }}>
                                            <Avatar
                                                alt={selectedBarber.user_name}
                                                src={`${imageURL}${selectedBarber.user_icon}`}
                                                sx={{
                                                    minWidth: '70px',
                                                    minHeight: '70px',
                                                    '& img': {
                                                        objectPosition: 'top',
                                                    },
                                                }}
                                            />

                                            <Box sx={{}}>
                                                <Typography variant="body1">{selectedBarber.user_name}</Typography>
                                                <Typography variant="body2">{selectedBarber.user_role}</Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                ))}
                            </Box>
                            <Box>
                                {selectedDate.map((index) => (
                                    <Paper
                                        key={index}
                                        elevation={3}
                                        sx={{
                                            display: 'flex',
                                            p: 2,
                                            mb: 2,
                                            mt: 2,
                                            height: 'auto',
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', gap: '10px' }}>
                                            <CalendarMonthIcon />
                                            <Typography variant="body1">{selectedDateTime}</Typography>
                                        </Box>
                                    </Paper>
                                ))}
                            </Box>
                            <Box>

                            </Box>
                            <Box sx={{ borderTop: '2px solid #3f3f3f', marginTop: '10px', marginBottom: '10px' }} >
                                <Paper
                                    elevation={3}
                                    sx={{
                                        p: 2,
                                        mt: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px'
                                        }}
                                    >
                                        <TextField id="standard-basic"
                                            label="Discount Code"
                                            variant="standard"
                                            value={discountCodeValue}
                                            onChange={handleDiscountCodeChange}
                                        />
                                        <Button variant="contained"
                                            sx={{ marginTop: 'auto', fontSize: '12px', backgroundColor: 'rgb(63, 63, 63)', textTransform: 'capitalize' }}
                                            onClick={handleActivateDiscount}
                                        >
                                            Activate
                                        </Button>
                                    </Box>
                                    <Typography variant="body2" sx={{ marginTop: '10px' }}>
                                        {discountCodeName && `Discount : ${discountCodeName}%`}
                                        {discountError && <span style={{ color: 'red' }}>{discountError}</span>}
                                    </Typography>
                                    <Typography variant="body2" sx={{ marginTop: '10px' }}>
                                        Total Price: {calculateTotalPrice() !== null ? `${calculateTotalPrice()} €` : ''}
                                    </Typography>
                                    <Typography variant="body2">
                                        Duration: {calculateTotalTime() !== null ? `${calculateTotalTime()} minutes` : ''}
                                    </Typography>
                                </Paper>
                            </Box>
                            <Box sx={{ marginTop: 'auto', width: '100%' }}>
                                <Button
                                    variant="contained"
                                    onClick={handleProceed}
                                    sx={{ marginTop: 'auto', width: '100%', backgroundColor: 'rgb(63, 63, 63)', textTransform: 'capitalize' }}
                                >
                                    Proceed
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>

                </Grid>
            </Container>
            <Footer />
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Validation Error</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => setDialogOpen(false)}
                        sx={{ backgroundColor: 'rgb(63, 63, 63)', textTransform: 'capitalize' }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={successDialogOpen}
                onClose={handleCloseBooking}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
            >
                <DialogTitle>{dialogTitle.includes('Error') ? 'Error' : 'Success'}</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <DialogContentText>{dialogMessage}</DialogContentText>
                        {dialogTitle.includes('Error') ? <ErrorAnimation /> : <SuccessAnimation />}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseBooking}
                        sx={{ backgroundColor: 'rgb(63, 63, 63)', textTransform: 'capitalize' }}
                    >Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
