import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Card, CardContent, Grid } from '@mui/material';
import Image from 'mui-image';
import Skeleton from "@mui/material/Skeleton";

const TeamMemberCard = ({ member }) => {
    const imageURL = "https://barbers-back-end.onrender.com/images/";

    return (
        <Card>
            <Image
                src={`${imageURL}${member.user_icon}`}
                alt="Icon"
                sx={{ width: '100%', maxHeight: '400px', objectFit: 'cover', objectPosition: 'top' }}
            />
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    {member.user_name}
                </Typography>
                <Typography variant="body2">
                    {member.user_role}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                    {member.user_bio}
                </Typography>
            </CardContent>
        </Card>
    );
};



const SkeletonCard = () => (
    <Card>
        <Skeleton
            variant="rectangular"
            width="100%"
            height={400}
            animation="wave"
        />

        <CardContent>
            <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                {[...Array(3)].map((_, index) => (
                    <Skeleton
                        key={index}
                        animation="wave"
                    />
                ))}
            </Typography>
        </CardContent>
    </Card>
);


const OurTeam = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const baseURL = "https://barbers-back-end.onrender.com";
    useEffect(() => {
        axios.get(`${baseURL}/our-team`, {
            withCredentials: true
        })
            .then(response => {
                setTeamMembers(response.data);
            })
            .catch(error => {
                console.error('Error fetching team data:', error);
            });
    }, []);

    return (
        <>
            <Typography variant="h5" gutterBottom>
                Meet Our Team
            </Typography>

            <Grid container spacing={2}>
                {teamMembers.length > 0 ? (
                    teamMembers.map((member, index) => (
                        <Grid key={index} item xs={12} md={6} lg={4}>
                            <TeamMemberCard member={member} />
                        </Grid>
                    ))
                ) : (
                    Array.from({ length: 4 }).map((_, i) => (
                        <Grid key={i} item xs={12} md={6} lg={4}>
                            <SkeletonCard />
                        </Grid>
                    ))
                )}
            </Grid>
        </>
    );
};

export default OurTeam;